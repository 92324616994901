import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "revision-browser__option pb-64" }
const _hoisted_2 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("iframe", {
      ref: "tour_iframe",
      id: "embed",
      class: "tour",
      src: `https://explore.the-boundary.com/space/${$props.option.tour_id}?comments=true`,
      frameborder: "0"
    }, null, 8 /* PROPS */, _hoisted_2)
  ]))
}