<template>
	<div class="revision-browser__option pb-64">
		<iframe
			ref="tour_iframe"
			id="embed"
			class="tour"
			:src="`https://explore.the-boundary.com/space/${option.tour_id}?comments=true`"
			frameborder="0"
		/>
	</div>
</template>
<script setup>
	import { ref, inject, defineProps, onMounted, onBeforeUnmount, computed, nextTick, watch } from 'vue';
	import { useDataStore } from '../stores/data';

	const props = defineProps( {
		option: {
			required: true,
			type: Object
		},
	} );

	const iframe_ready = ref( false );
	const comments_ready = ref( false );
	const tour_iframe = ref( null );

	const comments = ref( [] );

	const { active_hotspot, hotspot_data, updateHotspotData, updateActiveHotspot, updateActiveVersionId } = inject( 'hotspot-data' );

	const {
		getCommentsByOptionId
	} = useDataStore();

	const getComments = async () => {
		const response = await getCommentsByOptionId( parseInt( props.option.id ) );
		if ( response.data ) {
			comments.value = response.data.entries.reverse();
		}

		comments_ready.value = true;
		updateFrameComments();
	};

	const comments_for_frame = computed( () => {
		if ( comments.value.length === 0 ) {
			return comments.value;
		}

		return comments.value.map( comment => {
			return {
				id: comment.id.toString(),
				position: {
					x: parseFloat( comment.position_x ),
					y: parseFloat( comment.position_y ),
					z: parseFloat( comment.position_z )
				},
				cameraId: comment.camera_id,
				versionId: comment.version_id
			}
		} );
	} );

	const handleExploreMessages = ( event ) => {
		// const iframe_base_url = '';
		// if ( event.origin !== iframe_base_url ) {
		// 	return;
		// }

		const { type, data } = event.data;
		switch( type ) {
			case 'be-ready':
				setFrameReady( true );
				updateActiveVersionId( null );

				break;

			case 'be-new-comment':
				startComment( data );

				break;

			case 'be-camera-change':
				updateActiveHotspot( null );

				break;

			case 'be-version-change':
				updateActiveVersionId( data.id );

				break;

			case 'be-comment-selected':
				// Handle comment selection
				console.log('Comment selected:', data.id);
				break;

			default:
				console.log( type );
		}
	}

	onMounted( async () => {
		await nextTick();
		getComments();

		window.addEventListener( 'message', handleExploreMessages );
	} );

	onBeforeUnmount( () => {
		updateActiveHotspot( null );
		updateActiveVersionId( null );

		window.removeEventListener( 'message', handleExploreMessages );
	} );

	const startComment = ( data ) => {
		updateHotspotData( {
			camera_id: data.cameraId ?? null,
			version_id: data.versionId ?? null,
			position_x: data.position.x.toString() ?? null,
			position_y: data.position.y.toString() ?? null,
			position_z: data.position.z.toString() ?? null,
		} );
	}

	const setFrameReady = ( bool ) => {
		iframe_ready.value = bool;
		updateFrameComments();
	}

	const updateFrameComments = () => {
		if ( !iframe_ready.value || !comments_ready.value || !tour_iframe.value ) {
			return;
		}
		console.log( 'COMMENTS SENDING: ', comments_for_frame.value );
		tour_iframe.value.contentWindow.postMessage( {
			type: 'be-set-comments',
			data: comments_for_frame.value
		}, 'https://boundary-explore-git-dev-boundary.vercel.app/' );
	}

	const setActiveHotspot = () => {
		tour_iframe.value.contentWindow.postMessage( {
			type: 'be-select-comment',
			data: {
				id: active_hotspot.value.id
			}
		}, 'https://boundary-explore-git-dev-boundary.vercel.app/' );
	}

	watch( hotspot_data, ( hotspot_data ) => {
		if ( hotspot_data === null ) {
			getComments();
		}
	} );

	watch( active_hotspot, () => {
		console.log('active hotspot changed' );
		if ( active_hotspot === null ) {
			return;
		}

		setActiveHotspot();
	} );
</script>
